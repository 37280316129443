import React, {Fragment} from 'react';
import Link from '../../../../../../components/Link';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import {makeStyles} from '@material-ui/styles';
import {pink, blue} from '@material-ui/core/colors'

// Material components
import {
    Collapse,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    Typography,
} from '@material-ui/core';

// Material icons
import {
    Delete as DeleteIcon,
    Error as ErrorIcon,
    Warning as WarningIcon,
    Info as InfoIcon,
    Grade as StarIcon,
    Favorite as HeartIcon,
} from '@material-ui/icons';

// notification icons for each type
const icons = {
    info: {
        icon: <InfoIcon/>,
    },
    error: {
        icon: <ErrorIcon/>,
    },
    warning: {
        icon: <WarningIcon/>,
    },
    star: {
        icon: <StarIcon/>,
    },
    heart: {
        icon: <HeartIcon/>,
    }
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '350px',
        maxWidth: '100%'
    },
    header: {
        backgroundColor: theme.palette.background.default,
        backgroundPositionX: 'right',
        backgroundPositionY: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        paddingBottom: '34px',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '34px'
    },
    subtitle: {
        color: theme.palette.text.secondary
    },
    content: {},
    listItem: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.background.default
        }
    },
    listItemIcon: {
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
        padding: '10px',
        borderRadius: '50%',
        marginRight: theme.spacing(2)
    },
    listItemTextSecondary: {
        marignTop: '4px',
        color: theme.palette.text.secondary
    },
    arrowForward: {
        color: theme.palette.text.secondary,
        height: '16px',
        width: '16px'
    },
    footer: {
        paddingBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center'
    },
    empty: {
        textAlign: 'center',
        padding: theme.spacing(3)
    },
    emptyImageWrapper: {
        marginBottom: theme.spacing(3)
    },
    emptyImage: {
        width: '240px',
        maxWidth: '100%',
        height: 'auto'
    },
    info: {
        color: blue['A400']
    },
    error: {
        color: theme.palette.error.main
    },
    warning: {
        color: theme.palette.warning.main
    },
    star: {
        color: theme.palette.secondary.main
    },
    heart: {
        color: pink['A400']
    },
}));

const NotificationList = props => {

    const classes = useStyles();
    const [deleted, setDeleted] = React.useState([]);


    const handleClearNotification = id => event => {
        setDeleted(prevState => prevState.concat([id]));
        setTimeout(() => props.onClear(id), 500);
    }


    const {notifications, className} = props;
    const rootClassName = classNames(classes.root, className);

    const renderNotifications = () => {
        let listItemProps, iconKey;
        return notifications.map((notification, index) => {
            listItemProps = {};
            if (notification.link) {
                listItemProps = {
                    component: Link,
                    href: notification.link,
                    underline: 'none'
                }
            }
            iconKey = (icons[notification.type]) ? notification.type : 'info';
            return (
                <Collapse
                    key={notification.key}
                    timeout="auto"
                    in={!deleted.includes(notification.id)}
                >
                    <ListItem className={classes.listItem} {...listItemProps}>
                        <ListItemIcon
                            className={classNames([classes.listItemIcon, classes[iconKey]])}
                        >
                            {icons[iconKey].icon}
                        </ListItemIcon>
                        <ListItemText
                            classes={{secondary: classes.listItemTextSecondary}}
                            primary={notification.title}
                            secondary={notification.subtitle}
                        />
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={handleClearNotification(notification.id)}
                            >
                                <DeleteIcon className={classes.arrowForward}/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    {((index + 1) !== notifications.length) && <Divider/>}
                </Collapse>
            )
        });
    }


    return (
        <div className={rootClassName}>
            {notifications.length > 0 ? (
                <Fragment>
                    <div className={classes.header}>
                        <Typography variant="h5">Autohost Notifications</Typography>
                        <Typography
                            className={classes.subtitle}
                            variant="body1"
                        >
                            {notifications.length} new notifications
                        </Typography>
                    </div>
                    <div className={classes.content}>
                        <List component="div">
                            {renderNotifications()}
                        </List>
                    </div>
                </Fragment>
            ) : (
                <div className={classes.empty}>
                    <div className={classes.emptyImageWrapper}>
                        <img
                            alt="Empty list"
                            className={classes.emptyImage}
                            src="/static/empty.png"
                        />
                    </div>
                    <Typography variant="h4">There's nothing here...</Typography>
                </div>
            )}
        </div>
    );
};

NotificationList.propTypes = {
    className: PropTypes.string,
    notifications: PropTypes.array.isRequired,
    onClear: PropTypes.func
};

NotificationList.defaultProps = {
    notifications: [],
    onClear: () => {
    }
};

export default NotificationList;
