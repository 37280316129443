import React, {Fragment} from 'react';

// Externals
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import Head from 'next/head';

// Material UI components and helpers
import {makeStyles} from '@material-ui/styles';
import {CircularProgress} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

// Custom components
import {Topbar, Footer, Sidebar} from './components';
import {Content} from '../../components/Styles';
import ErrorBoundary from '../../components/ErrorBoundary';


const useStyles = makeStyles(theme => ({
    container: {
        minHeight: '100vh',
        display: 'flex',
        '@media all and (-ms-high-contrast:none)': {
            height: 0 // IE11 fix
        }
    },
    content: {
        paddingTop: 64 + theme.spacing(3),
        paddingBottom: theme.spacing(3),
        flexGrow: 1,
        maxWidth: '100%',
        overflowX: 'hidden',
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 56 + theme.spacing(3)
        }
    },
    alert: {
        margin: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '70%',
        // maxWidth: '400px',
    },
}));

const Dashboard = (props) => {
    const classes = useStyles();
    const user = useSelector(state => state.auth.user);
    const [openNavBarMobile, setOpenNavBarMobile] = React.useState(false);
    const {title, children} = props;
    const isSuspended = Boolean((user || {}).is_suspended);

    return (
        <Fragment>
            {title && <Head><title>{title}</title></Head>}
            <Topbar
                onOpenNavBarMobile={() => setOpenNavBarMobile(true)}
                user={user}
            />
            <Sidebar
                onMobileClose={() => setOpenNavBarMobile(false)}
                openMobile={openNavBarMobile}
                user={user}
            />
            <div className={classes.container}>
                <ErrorBoundary>
                    <div className={classes.content}>
                        {isSuspended &&
                            <Alert severity="error" className={classes.alert}>
                                <AlertTitle>Account Suspended</AlertTitle>
                                Your account has been suspended. Changes to account settings is not allowed. Kindly
                                contact your account manager as soon as possible.
                            </Alert>
                        }
                        {(user && user.id) ? children : <Content><CircularProgress/></Content>}
                        <Footer/>
                    </div>
                </ErrorBoundary>
            </div>
        </Fragment>
    );
};

Dashboard.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    title: PropTypes.string,
};

export default Dashboard;