import dynamic from 'next/dynamic';
import {useRouter} from 'next/router';
import React from 'react';
import {connect} from 'react-redux';

// actions
import {searchUsers, selectUser} from '../../../../reducers/admin';
import {clearNotification, getNotifications, logout, setAuth} from '../../../../reducers/auth';
import {clearSearch as clearListingSearch, search as searchListings} from '../../../../reducers/listings';
import {clearSearch as clearReservationSearch, search as searchReservations} from '../../../../reducers/reservations';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

// Material helpers
import {makeStyles} from '@material-ui/styles';

// Material components
import {AppBar, Badge, Hidden, IconButton, Popover, Toolbar, Typography, colors} from '@material-ui/core';

// Material icons
import {Input as InputIcon, Menu as MenuIcon, NotificationsOutlined as NotificationsIcon} from '@material-ui/icons';

// utils
import config from '../../../../config';
import {eraseCookie} from '../../../../libs/cookies';
import {invertHex} from '../../../../libs/utils';

// Custom components
import Link from '../../../../components/Link';
import NotificationList from './components/NotificationList'; // import normally to avoid UI positioning issue
const SearchAppBar = dynamic(() => import('./components/Search'));

export function setTextAndBackgroundColor(theme, user) {
    let textColor = theme.palette.text.secondary;
    let backgroundColor = theme.palette.common.white;
    //// uncomment to enable customer branding colors on AppBar
    // const company = user.company || {};
    // if (company.color_primary) {
    //     textColor = (company.color_secondary && company.color_secondary.length > 4 && company.color_secondary !== company.color_primary) ? company.color_secondary : invertHex(company.color_primary);
    //     backgroundColor = company.color_primary;
    // }
    // if (company.color_secondary) {
    //     textColor = (company.color_primary && company.color_primary.length > 4 && company.color_primary !== company.color_secondary) ? company.color_primary : invertHex(company.color_secondary);
    //     backgroundColor = company.color_secondary;
    // }
    if (user.type === 'admin') {
        textColor = invertHex(colors.orange[600]);
        backgroundColor = colors.orange[600];
    }
    if (!config.isProd && !user.selfserve) {
        textColor = invertHex(theme.palette.secondary.main);
        backgroundColor = theme.palette.secondary.main;
    }
    return {textColor, backgroundColor};
}

const useStyles = (user = {}) =>
    makeStyles((theme) => {
        let {textColor, backgroundColor} = setTextAndBackgroundColor(theme, user);
        return {
            root: {
                // boxShadow: 'none',
                color: textColor,
            },
            appbarBackground: {
                backgroundColor: backgroundColor,
            },
            flexGrow: {
                flexGrow: 1,
            },
            logoImage: {
                cursor: 'pointer',
                maxWidth: '156px',
                maxHeight: '55px',
                display: 'table-footer-group',
            },
            menuButton: {
                marginRight: theme.spacing(1),
            },
            notificationsButton: {
                marginLeft: theme.spacing(1),
            },
            notificationsBadge: {
                backgroundColor: colors.orange[600],
            },
            logoutButton: {
                marginLeft: theme.spacing(1),
            },
            logoutIcon: {
                marginRight: theme.spacing(1),
            },
        };
    });

const Topbar = (props) => {
    const {user} = props;
    const company = user.company || {};

    const router = useRouter();
    const classes = useStyles(user)();
    const [notificationsEl, setNotificationsEl] = React.useState(null);

    React.useEffect(() => {
        props.getNotifications(1000);
    }, []);

    const handleSignOut = () => {
        props.logout().then(() => {
            eraseCookie(config.cookieName);
            props.setAuth({user: {}, authenticated: false, session: null});
            typeof window !== 'undefined' ? (window.location = '/login') : router.push('/login');
        });
    };

    const handleSearch = (value) => {
        props.searchReservations(value, {size: 100});
        props.searchListings(value, {size: 100});
        if (user && ['admin', 'channel'].includes(user.type)) {
            props.searchUsers(value, {size: 100}, 'search');
        }
    };

    const handleClearSearch = () => {
        props.clearReservationSearch();
    };

    const handleShowNotifications = (event) => {
        setNotificationsEl(event.currentTarget);
    };

    const handleCloseNotifications = () => {
        setNotificationsEl(null);
    };

    const handleClearNotification = (id) => {
        props.clearNotification(id);
    };

    const {className, reservations, notifications, listings, users, onOpenNavBarMobile, selectUser} = props;

    const rootClassName = classNames(classes.root, className);
    const showNotifications = Boolean(notificationsEl);
    const notificationsCount = (notifications && notifications.length) || 0;

    let logoImageURL = company.logo_url || '/static/Autohost.svg';
    //// uncomment to enable customer branding colors on AppBar
    // if (company.logo_contrast_url && company.color_primary) {
    //     logoImageURL = company.logo_contrast_url
    // }
    // if (company.logo_url && company.color_secondary) {
    //     logoImageURL = company.logo_url
    // }
    let logoImage = <img alt="Logo" src={logoImageURL} className={classes.logoImage} />;
    if (user.type === 'admin' || !config.isProd) {
        logoImage = (
            <Typography variant="h2" className={classes.logoImage}>
                {user.type === 'admin' ? 'Admin' : user.selfserve ? 'Demo' : 'DEV'}
            </Typography>
        );
    }

    return (
        <AppBar
            className={rootClassName}
            classes={{colorPrimary: classes.appbarBackground}}
            variant="outlined"
            square={true}
            elevation={1}
        >
            <Toolbar>
                <Hidden lgUp>
                    <IconButton className={classes.menuButton} color="inherit" onClick={onOpenNavBarMobile}>
                        <MenuIcon />
                    </IconButton>
                </Hidden>
                <Link href="/">{logoImage}</Link>
                <div className={classes.flexGrow} />
                <SearchAppBar
                    searchResults={{listings, reservations, users}}
                    selectUser={selectUser}
                    userType={user.type}
                    onSearch={handleSearch}
                    onClear={handleClearSearch}
                    user={user}
                />
                <Hidden smDown>
                    <IconButton
                        className={classes.notificationsButton}
                        color="inherit"
                        onClick={handleShowNotifications}
                    >
                        <Badge badgeContent={notificationsCount} color="secondary" variant="dot">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                </Hidden>
                <IconButton className={classes.signOutButton} color="inherit" onClick={handleSignOut}>
                    <InputIcon />
                </IconButton>
            </Toolbar>
            <Popover
                anchorEl={notificationsEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                onClose={handleCloseNotifications}
                open={showNotifications}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <NotificationList notifications={notifications} onClear={handleClearNotification} />
            </Popover>
        </AppBar>
    );
};

Topbar.propTypes = {
    // from parent
    className: PropTypes.string,
    user: PropTypes.object.isRequired,
    onOpenNavBarMobile: PropTypes.func,

    // connected
    notifications: PropTypes.array,
    setAuth: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    searchReservations: PropTypes.func.isRequired,
    searchListings: PropTypes.func.isRequired,
    searchUsers: PropTypes.func.isRequired,
    selectUser: PropTypes.func.isRequired,
    clearReservationSearch: PropTypes.func.isRequired,
    clearListingSearch: PropTypes.func.isRequired,
    getNotifications: PropTypes.func.isRequired,
    clearNotification: PropTypes.func.isRequired,
    reservations: PropTypes.array,
    listings: PropTypes.array,
    users: PropTypes.array,
};

const mapStateToProps = (state) => {
    return {
        reservations: state.reservations.search,
        listings: state.listings.search,
        users: state.admin.search,
        notifications: state.auth.notifications,
    };
};

export default compose(
    connect(mapStateToProps, {
        setAuth,
        logout,
        searchReservations,
        searchListings,
        searchUsers,
        clearReservationSearch,
        clearListingSearch,
        getNotifications,
        clearNotification,
        selectUser,
    }),
)(Topbar);
