import React, {Component} from 'react';
import Router from 'next/router';
import Head from 'next/head';

// Externals
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

// Material helpers
import {withStyles, withWidth} from '@material-ui/core';
import Button from '@material-ui/core/Button';

// Custom components
import {Footer} from '../Dashboard/components';

// Component styles
import styles from './styles';
import Link from '../../components/Link';
import {Spacing} from '../../components/Styles';
import CookieConsent from '../../components/CookieConsent';


// Track client-side page views with Segment
Router.events.on('routeChangeComplete', url => {
    typeof window !== 'undefined' && window.analytics && window.analytics.page(url)
})


class Signup extends Component {
    render() {
        const {classes, user, router, children, logoSource, logoLink, withFooter} = this.props;

        let logo = <img width="200px" src={logoSource}/>;
        if (logoLink && logoLink.startsWith('http')) {
            logo = <a href={logoLink}><img width="200px" src={logoSource}/></a>;
        }
        if (logoLink && logoLink.startsWith('/')) {
            logo = <Link href={logoLink}><img width="200px" src={logoSource}/></Link>;
        }

        return (
            <div className='layout'>

                <Head>
                    <script src="https://js.stripe.com/v3/"/>
                </Head>

                <header>
                    {logo}
                    {user && user.id &&
                    <React.Fragment>
                        <div className={classes.flexGrow}/>
                        <Button
                            onClick={() => router.push('/logout')}
                            variant="outlined"
                            color="primary"
                            className="logout"
                        >
                            LOGOUT
                        </Button>
                    </React.Fragment>
                    }
                    <style jsx>{`
                        header {
                          display: flex;
                          align-items: center;
                          font-family: Monospace;
                          margin: 10px 20px;
                        }
                        h3 {
                          margin-left: 10px
                        }
                        img {
                          width: 200px;
                        }
                        menu > a {
                          margin-right: 16px;
                        }
                    `}</style>
                </header>
                <main
                    className={classes.content}
                >
                    {children}
                    <Spacing/>
                    {withFooter && <Footer/>}
                    <CookieConsent/>
                </main>
            </div>
        );
    }
}

Signup.propTypes = {
    children: PropTypes.node,
    withFooter: PropTypes.bool,
    logoSource: PropTypes.string,
    logoLink: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired
};

Signup.defaultProps = {
    withFooter: true,
    logoSource: '/static/Autohost.svg',
    logoLink: '/'
};

export default compose(
    withStyles(styles),
    withWidth()
)(Signup);