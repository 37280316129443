import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import styles from './styles';
import theme from '../../theme/index';

const useStyles = makeStyles(styles);

const EmbeddedIframe = ({children, bgc = theme.palette.background.default}) => {
    const classes = useStyles();
    if (bgc && !bgc.startsWith('#')) {
        bgc = `#${bgc}`;
    }
    console.log(bgc);
    return (
        <div className="layout">
            <style jsx>{`
                ${bgc ? `
                html, body {
                    background-color: ${bgc}                
                }
                main {
                    background-color: ${bgc}
                }
                ` : ''}
                
            `}</style>
            <main
                className={classes.content}
            >
                {children}
            </main>
        </div>
    );
};

export default EmbeddedIframe;