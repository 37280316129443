import React from 'react'
import config from '../config'
import {Content} from './Styles'
import Typography from '@material-ui/core/Typography'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        const {extra} = this.props;
        const datadog = typeof window !== 'undefined' && window.DD_RUM;
        const rollbar = typeof window !== 'undefined' && window.Rollbar;
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        const report = {
            message: error && error.toString(),
            error: {
                ...errorInfo,
                extra,
            },
        };
        if (report.message) {
            if (rollbar) {
                rollbar.error(report.message, report.error);
            }
            if (datadog) {
                datadog.addAction('Exception Caught', {error, errorInfo, ...extra});
            }
        }
        // return super.componentDidCatch(error, errorInfo)
    }

    render() {
        const isDev = (!config.stage || !config.stage.includes('prod'));
        if (this.state.errorInfo) {
            let title = this.state.error && this.state.error.toString();
            let subtitle = this.state.errorInfo.componentStack;
            if (!isDev) {
                title = 'Oops';
                subtitle = 'This component has stopped working. Our technical team has been notified. Please try again later.';
            }
            return (
                <Content align="left">
                    <Typography
                        variant="h4"
                        color="textSecondary"
                        gutterBottom
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        gutterBottom
                    >
                        {subtitle}
                    </Typography>
                </Content>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary