import React, {Fragment} from 'react';
import {useRouter} from 'next/router'


// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import {makeStyles} from '@material-ui/core/styles';

// Material components
import {
    Divider,
    Drawer,
    Hidden,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
} from '@material-ui/core';

// Material icons
import {
    HelpOutline as HelpIcon,
    People as PeopleIcon,
    Favorite as BillingIcon,
    Map as MapIcon,
    ShowChart as ChartsIcon,
    ListAlt as LogsIcon,
} from '@material-ui/icons';

// Custom components
import {
    GuestPortalIcon,
    ProtectIcon,
    ReservationsIcon,
    ListingIcon,
    SettingsIcon,
    BuildingIcon,
    StargateIcon,
} from '../../../../components/Icons'
import Link from '../../../../components/Link'

// Component styles
const useStyles = company => makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(3)
    },
    mobileDrawer: {
        width: 256,
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)'
    },
    navigation: {
        overflow: 'auto',
        padding: theme.spacing(0, 2, 2, 2),
        flexGrow: 1
    },
    listSubheader: {
        color: theme.palette.text.secondary
    },
    listItem: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            borderLeft: `4px solid ${company.color_primary || theme.palette.secondary.main}`,
            borderRadius: '4px',
            '& $listItemIcon': {
                color: company.color_primary || theme.palette.secondary.main,
                marginLeft: '-4px'
            }
        },
        '& + &': {
            marginTop: theme.spacing(1)
        }
    },
    activeListItem: {
        borderLeft: `4px solid ${company.color_primary || theme.palette.secondary.main}`,
        borderRadius: '4px',
        backgroundColor: theme.palette.secondary.light,
        '& $listItemText': {
            color: theme.palette.text.primary
        },
        '& $listItemIcon': {
            color: company.color_primary || theme.palette.secondary.main,
            marginLeft: '-4px'
        }
    },
    listItemIcon: {
        marginRight: 0
    },
    listItemText: {
        fontWeight: 500,
        color: theme.palette.text.secondary
    },
    listDivider: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    }
}));


let Sidebar = ({className, openMobile, onMobileClose, user = {}}) => {
    const router = useRouter();
    const userType = user && user.subtype;
    const company = user.company || {};
    const classes = useStyles(company)();
    const rootClassName = classNames(classes.root, className);

    React.useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
    }, [router.asPath]);

    const checkIsActive = (href) => {
        return router.asPath.startsWith(href);
    };
    const checkIsActiveSpecial = (href = '') => {
        return router.asPath.startsWith(href.substring(0, href.length - 1));
    };


    const content = (
        <div className={rootClassName}>
            <nav className={classes.navigation}>
                <List
                    component="div"
                    disablePadding
                >
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={Link}
                        checkIsActive={checkIsActiveSpecial}
                        href="/reservations"
                        underline="none"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <ReservationsIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Reservations"
                        />
                    </ListItem>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={Link}
                        checkIsActive={checkIsActiveSpecial}
                        href="/listings?status=active"
                        underline="none"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <ListingIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Listings"
                        />
                    </ListItem>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={Link}
                        checkIsActive={checkIsActive}
                        href="/buildings"
                        underline="none"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <BuildingIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Buildings"
                        />
                    </ListItem>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={Link}
                        checkIsActive={checkIsActive}
                        href="/activity"
                        underline="none"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <LogsIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Activity Log"
                        />
                    </ListItem>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={Link}
                        checkIsActive={checkIsActive}
                        href="/analytics"
                        underline="none"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <ChartsIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Analytics"
                        />
                    </ListItem>
                </List>
                {/*<div className={classes.listDivider}/>*/}
                {userType && ['owner', 'admin'].includes(userType) &&
                <List
                    component="div"
                    disablePadding
                    subheader={
                        <ListSubheader className={classes.listSubheader}>
                            Controls
                        </ListSubheader>
                    }
                >
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={Link}
                        checkIsActive={checkIsActive}
                        href="/protect"
                        underline="none"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <ProtectIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Screening Assistant"
                        />
                    </ListItem>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={Link}
                        checkIsActive={checkIsActive}
                        href="/guestportal"
                        underline="none"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <GuestPortalIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Guest Portal"
                        />
                    </ListItem>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={Link}
                        checkIsActive={checkIsActive}
                        href="/settings"
                        underline="none"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <SettingsIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Settings"
                        />
                    </ListItem>

                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={Link}
                        checkIsActive={checkIsActive}
                        href="/accounts"
                        underline="none"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <PeopleIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Accounts"
                        />
                    </ListItem>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={Link}
                        checkIsActive={checkIsActive}
                        href="/billing"
                        underline="none"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <BillingIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Billing"
                        />
                    </ListItem>
                    <ListItem
                        className={classes.listItem}
                        component="a"
                        href="https://help.autohost.ai/knowledge"
                        target="_blank"
                        underline="none"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <HelpIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Help"
                        />
                    </ListItem>
                </List>
                }
                {user && user.type === 'admin' &&
                <Fragment>
                    <Divider className={classes.listDivider}/>
                    <List
                        component="div"
                        disablePadding
                        subheader={
                            <ListSubheader className={classes.listSubheader}>
                                Admin
                            </ListSubheader>
                        }
                    >
                        <ListItem
                            activeClassName={classes.activeListItem}
                            className={classes.listItem}
                            component={Link}
                            checkIsActive={checkIsActive}
                            href="/users"
                            underline="none"
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <PeopleIcon/>
                            </ListItemIcon>
                            <ListItemText
                                classes={{primary: classes.listItemText}}
                                primary="Users"
                            />
                        </ListItem>
                        <ListItem
                            activeClassName={classes.activeListItem}
                            className={classes.listItem}
                            component={Link}
                            checkIsActive={checkIsActive}
                            href="/admin/listings"
                            underline="none"
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <MapIcon/>
                            </ListItemIcon>
                            <ListItemText
                                classes={{primary: classes.listItemText}}
                                primary="Map"
                            />
                        </ListItem>
                    </List>
                </Fragment>
                }
                {user && user.type === 'channel' &&
                <Fragment>
                    <Divider className={classes.listDivider}/>
                    <List
                        component="div"
                        disablePadding
                        subheader={
                            <ListSubheader className={classes.listSubheader}>
                                Reseller Admin
                            </ListSubheader>
                        }
                    >
                        <ListItem
                            activeClassName={classes.activeListItem}
                            className={classes.listItem}
                            component={Link}
                            checkIsActive={checkIsActive}
                            href="/users"
                            underline="none"
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <PeopleIcon/>
                            </ListItemIcon>
                            <ListItemText
                                classes={{primary: classes.listItemText}}
                                primary="Users"
                            />
                        </ListItem>
                    </List>
                </Fragment>
                }
            </nav>
        </div>
    )

    return (
        <Fragment>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{
                        paper: classes.mobileDrawer
                    }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{
                        paper: classes.desktopDrawer
                    }}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </Fragment>
    )

};

Sidebar.propTypes = {
    className: PropTypes.string,
    user: PropTypes.object.isRequired
};

export default Sidebar;