import React from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import {makeStyles} from '@material-ui/styles';

// Material components
import {Divider, Typography} from '@material-ui/core';

// Component styles
const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    company: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0.5)
    }
}));

const Footer = props => {
    const classes = useStyles();
    const {className} = props;
    const rootClassName = classNames(classes.root, className);
    return (
        <div className={rootClassName}>
            <Divider/>
            <Typography
                className={classes.company}
                variant="body1"
            >
                &copy; Autohost {new Date().getFullYear()}
            </Typography>
            <Typography variant="caption">
                Know your guest.
            </Typography>
        </div>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
};

export default Footer;