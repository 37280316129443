const styles = theme => ({
    content: {
        paddingTop: theme.spacing(2),
        // backgroundColor: theme.palette.background.default,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    flexGrow: {
        flexGrow: 1
    },
});

export default styles;