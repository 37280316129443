import React from 'react';
import {createCookie, readCookie} from '../libs/cookies';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import Link from './Link';

const CookieConsent = (props) => {
    const {
        cookieName = 'autohostCookieConsent',
        cookieValue = 'accepted',
        expires = new Date().getTime() + 3.154e+10,
        onAccept,
        onDecline = () => {if (window) window.location = 'https://www.autohost.ai';},
        snackbarAnchor = {horizontal: 'center', vertical: 'bottom'},
        message = <React.Fragment>🍪 We use cookies to provide necessary functionality and improve your experience. Read our <Link href="https://www.autohost.ai/privacy" target="_blank">Cookie Policy</Link>.</React.Fragment>,
        acceptButtonLabel = 'Accept',
        declineButtonLabel = 'Leave',
    } = props;
    const [visible, setVisible] = React.useState(false);

    React.useEffect(() => {
        const cookie = readCookie(cookieName);
        if (cookie === null) setVisible(true);
    }, []);

    const handleAccept = () => {
        if (onAccept) onAccept();
        createCookie(cookieName, cookieValue, expires);
        setVisible(false);
    };

    const actions = [
        <Button
            key="decline"
            color="inherit"
            size="small"
            onClick={onDecline}
        >
            {declineButtonLabel}
        </Button>,
        <Button
            key="accept"
            color="secondary"
            size="small"
            onClick={handleAccept}
        >
            {acceptButtonLabel}
        </Button>
    ];

    return (
        <Snackbar
            anchorOrigin={snackbarAnchor}
            open={visible}
            message={message}
            action={actions}
        />
    )
};

export default CookieConsent;